<template>
    <div class="body">
        <div id="spacer"></div>
        <v-container>
            <div class="body-content">
                <div align="left" >
                    <label class="label-content"><b>{{title}}</b></label>
                </div>
                <div align="left" v-if="title != ''">
                    <label class="label-content">{{$t("Updated")}} {{dateNow}}</label>
                </div>
                <br>
                <div align="left" v-html="contentDesc" style="font-family: 'Roboto';"></div>
            </div>
        </v-container>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: "PrivacyPolicy",
    data() {
        return {
            title: "",
            contentDesc: "",
            dateNow: "",
        }
    },
    mounted() {
         this.$store.dispatch("menuManagement/getPrivacyPolicy").then((response) => {
           if(response != null){
             this.title = response.title
             this.contentDesc = response.content_desc
             this.dateNow = moment().format("MMMM DD, YYYY")
           }
        }).catch(e => {
            console.log(e)
        })
    }
}
</script>

<style scoped>
#spacer {
  background: #f5fff7;
  padding: 6px;
}
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .body-space{
    background-color:#f5fdf7;
    height: 2%;
    width: 100%;
  }
  .body{
    background-color:#FFFFFF;
    height: 100%;
    width: 100%;
  }
  .logo-homepage{
    padding-top: 30px;
    padding-bottom: 30px;    
  }
  .img-body{
    height: 211px;
    width: 243.77471923828125px;
  }
  .label-content{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .body-content{
    padding-top: 10px;
    padding-bottom: 30px;
  }

}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .body-space{
    background-color:#f5fdf7;
    height: 3%;
    width: 100%;
  }
  .body{
    background-color:#FFFFFF;
    height: 100%;
    width: 100%;
  }
  .logo-homepage{
    padding-top: 30px;
    padding-bottom: 30px;    
  }
  .img-body{
    height: 211px;
    width: 243.77471923828125px;
  }
  .label-content{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .body-content{
    padding-top: 10px;
    padding-bottom: 30px;
  }

}
</style>